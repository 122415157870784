<template>
	<el-form ref="ruleForm" :model="ruleForm" :rules="rules" :label-width="$root.rwd < 3 ? '130px' : ''" :label-position="$root.rwd > 2 ? 'top' : 'left'">
		<el-form-item label="綽號" prop="nickname">
			<el-input v-model="ruleForm.nickname" class="round" />
		</el-form-item>
		<el-form-item label="飲食習慣" prop="diet">
			<el-radio v-model="ruleForm.diet" label="葷食" />
			<el-radio v-model="ruleForm.diet" label="素食(蛋、奶可)" />
			<el-radio v-model="ruleForm.diet" label="全素" />
		</el-form-item>
		<el-form-item label="最高學歷" prop="education">
			<div class="row-grid" align-y="center" gutter-y="10">
				<template v-for="(option, i) in education_options">
					<el-radio v-model="ruleForm.education" :label="option" :key="i" />
				</template>
				<div class="row" align-y="center">
					<el-radio v-model="ruleForm.education" :label="education_options.includes(ruleForm.education) ? '' : ruleForm.education">其他</el-radio>
					<el-input v-model="ruleForm.education" :disabled="education_options.includes(ruleForm.education)" placeholder="其他" class="round" />
				</div>
			</div>
		</el-form-item>
		<el-form-item label="服務單位／就讀學校" prop="unit">
			<el-input v-model="ruleForm.unit" class="round" />
		</el-form-item>
		<el-form-item label="社團、工作或志工服務經驗" prop="volunteer_experience">
			<el-input v-model="ruleForm.volunteer_experience" type="textarea" :autosize="{minRows:5}" />
		</el-form-item>
		<el-form-item label="個人專長" prop="personal_expertise">
			<el-input v-model="ruleForm.personal_expertise" type="textarea" :autosize="{minRows:5}" />
		</el-form-item>
		<el-form-item label="願意參與志工類型（可複選）" prop="volunteer_type">
			<el-checkbox-group v-model="ruleForm.volunteer_type">
				<template v-for="(option, i) in type_options">
					<el-checkbox :label="option" :key="i" />
				</template>
			</el-checkbox-group>
		</el-form-item>
		<el-form-item label="可服務區域（可複選）" prop="volunteer_region">
			<el-checkbox-group v-model="ruleForm.volunteer_region">
				<template v-for="(option, i) in region_options">
					<el-checkbox :label="option" :key="i" />
				</template>
			</el-checkbox-group>
		</el-form-item>
		<el-form-item label="訂閱">
			<el-checkbox v-model="ruleForm.subscribe" label="我同意收到金枝電子報" />
		</el-form-item>
	</el-form>
</template>

<script>
import { rule } from "@/utils/shared-methods";
import administrativeDistrict from "@/assets/data/administrative-district.json";
import { mapState } from "vuex";
const ruleForm = function () {
	return {
		volunteer_type: [],
		volunteer_region: [],
	}
}
export default {
	data() {
		return {
			administrativeDistrict,
			ruleForm: new ruleForm,
			rules: {
				diet: rule(),
				volunteer_experience: rule(),
				personal_expertise: rule(),
				volunteer_type: rule(),
				volunteer_region: rule(),
			},
			education_options: ["碩士(含)以上", "大專院校", "高中職", "國中"],
			type_options: [
				"行政：行政事務、資料蒐集及檔案建立等。大台北地區志工每週擇一日，固定時段至辦公室協助行政事務；外縣市志工可報名後電話聯繫，視活動內容需要協助在地工作。",
				"宣傳：支援美術製作、文宣、網路訊息編撰及露出等。",
				"新聞：展演現場實況文字記錄",
				"采風：展演現場實況攝影記錄",
				"活動：展演現場工作人員與前台服務人員",
			],
			region_options: [
				"全台走透透沒問題",
				"北部（台北、新北、基隆、宜蘭、桃園、新竹地區）",
				"中部（苗栗、台中、南投、彰化、雲林地區）",
				"南部（含嘉義、台南、高雄、屏東地區）",
				"東部（花蓮、台東地區）",
				"離島",
			],
		}
	},
	computed: {
		...mapState("auth", ["_auth"])
	},
	methods: {
		async validate() {
			return new Promise((resolve, reject) => {
				this.$refs["ruleForm"].validate((valid, object) => {
					if (valid) resolve(this.ruleForm);
					else reject("請確認表格皆填寫完畢");
				});
			});
		},
		resetFields() {
			this.ruleForm = new ruleForm;
			this.$refs["ruleForm"].resetFields();
		},
		checkMembership() {
			try {
				if (this._auth) {
					this.$set(this.$data, "ruleForm", this._.assign(new ruleForm, this._auth));
					const { roles } = this._auth;
					if (roles.includes("volunteer")) throw "謝謝您！您已加入金枝志工團！";
					else if (!roles.includes("member")) throw `請先加入「<a href="/join/member" style="color:#a98a4c;"><u>金粉會員</u></a>」`;
				} else throw `請先「<a href="/login" style="color:#a98a4c;"><u>登入</u></a>」或者加入「<a href="/join/member" style="color:#a98a4c;"><u>金粉會員</u></a>」`;
			} catch (error) {
				this.$alert(error, {
					type: "warning",
					confirmButtonText: "知道了",
					dangerouslyUseHTMLString: true
				});
			}
		},
	},
	created() {
		this.checkMembership();
	}
}
</script>